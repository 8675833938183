import { Controller } from "stimulus";
import WindowAnimation from "../lib/WindowAnimation";

export default class extends Controller {
  toggleNavTarget: HTMLElement;
  handle: number;

  static get targets() {
    return ["toggleNav"];
  }

  close(evt: Event) {
    if (this.element.classList.contains("show-nav")) {
      evt.preventDefault();
      this.element.classList.remove("show-nav");
      this.toggleNavTarget.ariaExpanded = "false";
    }
  }

  connect() {
    this.handle = WindowAnimation.register({
      scroll: (offset) => {
        if (offset > 100) {
          this.element.classList.add("scrolled");
        } else {
          this.element.classList.remove("scrolled");
        }
      }
    });
  }

  disconnect() {
    WindowAnimation.clear(this.handle);
  }

  toggleNav() {
    if (this.element.classList.contains("show-nav")) {
      this.element.classList.remove("show-nav");
      this.toggleNavTarget.ariaExpanded = "false";
    } else {
      this.element.classList.add("show-nav");
      this.toggleNavTarget.ariaExpanded = "true";
    }
  }
}
