// Entry point for the build script in your package.json

// Window animation
import WindowAnimation from "./lib/WindowAnimation";
WindowAnimation.start();

// Responsive embeds
import responsiveEmbeds from "./lib/responsiveEmbeds";
responsiveEmbeds();

// Grid overlay
import gridOverlay from "./lib/gridOverlay";
gridOverlay();

// Stimulus
import { Application } from "stimulus";
import ExpandableController from "./controllers/ExpandableController";
import HeaderController from "./controllers/HeaderController";
const application = Application.start();
application.register("expandable", ExpandableController);
application.register("header", HeaderController);

// mux-player
import "@mux/mux-player";
